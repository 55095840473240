"use strict";

//dotenv
require("dotenv").config();

// JavaScript Document
import $ from "jquery";
import "jquery-ui/ui/widgets/sortable.js";
import "what-input";
import Cookies from "js-cookie";

// COLOUR PICKER
import "spectrum-colorpicker";

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

window.$ = require("jquery");
window.jQuery = window.$;
window.Cookies = require("js-cookie");

$.fn.extend({
  trackChanges: function () {
    $(":input", this).change(function () {
      $(this.form).data("changed", true);
    });
  },
  isChanged: function () {
    return this.data("changed");
  },
});

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
require("foundation-sites");
require("../../node_modules/@fortawesome/fontawesome-free/js/all.min.js");

$(document).ready(function () {
  "use strict";

  Foundation.Abide.defaults.patterns["password"] =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/;

  Foundation.Abide.defaults.validators["file-validator"] = window.fileValidator;
  Foundation.Abide.defaults.validators["datetime-validator"] =
    window.datetimeValidator;

  //tooltip defaults
  Foundation.Tooltip.defaults.fadeInDuration = 0;
  Foundation.Tooltip.defaults.fadeOutDuration = 0;
  Foundation.Tooltip.defaults.allowHtml = true;
  Foundation.Tooltip.defaults.allowOverlap = true;
  Foundation.Tooltip.defaults.vOffset = 2;

  $(document).foundation();

  //disable free text entry on timepickers
  $("body").on("keypress", ".timepicker", function (event) {
    event.preventDefault();
  });

  $(window).resize(function () {
    let element = jQuery("[data-equalizer]");
    new Foundation.Equalizer(element);
  });

  $(window).on("changed.zf.mediaquery", function (_event, _newSize, _oldSize) {
    let element = jQuery("[data-equalizer]");
    new Foundation.Equalizer(element);
  });

  let tracking_consent = Cookies.get("mtm_consent");
  let tracking_consent_removed = Cookies.get("mtm_consent_removed");

  if (tracking_consent_removed) {
    $("#tracking").attr("data-current", "OFF");
    $("#slider.cookie span.disc").animate({ left: "5px" });
    $("#slider.cookie").removeClass("enabled").addClass("disabled");
    $("#analytics_toggle_SR").text("Swicth on analytics cookies");
  } else {
    $("#tracking").attr("data-current", "ON");
    $("#slider.cookie span.disc").animate({ left: "77%" });
    $("#slider.cookie").removeClass("disabled").addClass("enabled");
    $("#analytics_toggle_SR").text("Swicth off analytics cookies");
  }
  //========================================================//
  // CHECK COOKIE CONSENT
  //========================================================//

  if (!tracking_consent && !tracking_consent_removed) {
    //open the cookie offcanvas
    $(".cookie-consent").foundation("open");
  }

  //========================================================//
  // ANALYTICS TOGGLE
  //========================================================//
  $(".cookie-consent").on("click", ".analytics_toggle", function (e) {
    e.preventDefault();
    let current_tracking = $("#tracking").attr("data-current");
    //if success and value is 1 then slide toggle and enabled class
    if (current_tracking == "OFF") {
      $("#tracking").attr("data-current", "ON");
      $("#slider.cookie span.disc").animate({ left: "77%" });
      $("#slider.cookie").removeClass("disabled").addClass("enabled");
      $("#analytics_toggle_SR").text("Swicth off analytics cookies");
    } else {
      $("#tracking").attr("data-current", "OFF");
      $("#slider.cookie span.disc").animate({ left: "5px" });
      $("#slider.cookie").removeClass("enabled").addClass("disabled");
      $("#analytics_toggle_SR").text("Swicth on analytics cookies");
    }
  });

  //========================================================//
  // SAVE PREFERENCES
  //========================================================//
  $(".cookie-consent").on("click", "#btnSaveCookieConsent", function (e) {
    e.preventDefault();
    save_consent();
    $("#cookieConsentContainer").attr("data-toggle", "offCanvasRight");
    $(".cookie-consent").foundation("close");
  });

  //========================================================//
  // CLICK ON POSTER CARD :not(".active")
  //========================================================//
  $(".congress-body,.home-body,.therapy_area-body").on(
    "click",
    ".content-poster-card",
    function () {
      if (!$(this).hasClass("active")) {
        $(this).children(".poster-card-main").hide();
        $(this).children(".poster-card-hover").show();
        $(this).addClass("active");
      } else {
        $(this).children(".poster-card-hover").hide();
        $(this).children(".poster-card-main").show();
        $(this).removeClass("active");
      }
    }
  );

  //========================================================//
  // HOVER ON POSTER CARD
  //========================================================//
  $(".congress-body,.home-body,.therapy_area-body").on(
    "mouseenter",
    ".content-poster-card",
    function () {
      //stuff to do on mouse enter
      $(this).addClass("active");
      $(this).children(".poster-card-main").hide();
      $(this).children(".poster-card-hover").show();
    }
  );

  $(".congress-body,.home-body,.therapy_area-body").on(
    "mouseleave",
    ".content-poster-card",
    function () {
      //stuff to do on mouse leave
      $(this).removeClass("active");
      $(this).children(".poster-card-hover").hide();
      $(this).children(".poster-card-main").show();
    }
  );

  //========================================================//
  // CLOSE POSTER CARD
  //========================================================//
  $(".congress-body,.home-body,.therapy_area-body").on(
    "click",
    ".content-poster-card .card-close",
    function () {
      let id = $(this).data("id");
      if (
        $("#poster_" + id)
          .children(".content-poster-card")
          .hasClass("active")
      ) {
        $("#poster_" + id)
          .children(".content-poster-card")
          .children(".poster-card-hover")
          .hide();
        $("#poster_" + id)
          .children(".content-poster-card")
          .children(".poster-card-main")
          .show();
        $("#poster_" + id)
          .children(".content-poster-card")
          .removeClass("active");
      }
    }
  );

  //========================================================//
  // FILTER SLIDEDOWN
  //========================================================//
  $(".congress-body,.home-body,.therapy_area-body").on(
    "click",
    ".filter-dropdown",
    function () {
      let area = $(this).data("area");
      let type = $(this).attr("data-type");

      //open if closed
      if (type == "closed") {
        //slide it down
        $("#" + area).slideDown(300);
        //update the type
        $(this).attr("data-type", "open");
        //change the arrow
        $(this)
          .find(".filter-arrow svg")
          .removeClass("fa-sort-down")
          .addClass("fa-sort-up");
        _paq.push([
          "trackEvent",
          "Poster hall",
          "Show Filters",
          "Click",
          "Poster hall",
        ]);
      } else {
        //slide it up
        $("#" + area).slideUp(300);
        //update the type
        $(this).attr("data-type", "closed");
        //change the arrow
        $(this)
          .find(".filter-arrow svg")
          .removeClass("fa-sort-up")
          .addClass("fa-sort-down");
        _paq.push([
          "trackEvent",
          "Poster hall",
          "Hide Filters",
          "Click",
          "Poster hall",
        ]);
      }
    }
  );
});

//========================================================//
// 1. SAVE TRACKING CONSENT
//========================================================//
window.save_consent = function save_consent() {
  let current_tracking = $("#tracking").attr("data-current");
  //save consent or remove consent
  if (current_tracking == "ON") {
    _paq.push(["rememberConsentGiven"]);
    _paq.push(["rememberCookieConsentGiven"]);
    _paq.push(["setConsentGiven"]);
    _paq.push(["setCookieConsentGiven"]);
  } else {
    _paq.push(["forgetConsentGiven"]);
    _paq.push(["forgetCookieConsentGiven"]);
  }
};

//========================================================//
// 2. FILE VALIDATOR - CHECK MAX FILESIZE
//========================================================//
window.fileValidator = function fileValidator(
  $el /* jQuery element to validate */,
  required /* is the element required according to the `[required]` attribute */
) {
  let val = $el.val();
  let id = $el.attr("id");
  let type = $el.data("type");
  let pattern = $el.attr("pattern");

  if (required === true && !val) {
    return false;
  }

  let reg_ext = new RegExp(pattern);

  if (!reg_ext.test(val) && val) {
    $el.val(null);
    return false;
  }

  let size = 0;

  if ($("#" + id)[0].files[0]) {
    size = $("#" + id)[0].files[0].size;
  }
  let max_filesize = 0;

  switch (type) {
    case "logo":
      max_filesize = parseInt(process.env.MAX_LOGO_FILESIZE);
      break;
    case "image":
      max_filesize = parseInt(process.env.MAX_IMAGE_FILESIZE);
      break;
    case "doc":
      max_filesize = parseInt(process.env.MAX_DOC_FILESIZE);
      break;
    case "audio":
      max_filesize = parseInt(process.env.MAX_AUDIO_FILESIZE);
      break;
    default:
      max_filesize = parseInt(process.env.MAX_DOC_FILESIZE);
  }

  console.log("Size: ", size);
  console.log("Max: ", max_filesize);

  if (size > max_filesize) {
    $el.val(null);
    return false;
  }

  return true;
};

//========================================================//
// 3. START/END DATETIME CHECK
//========================================================//
window.datetimeValidator = function datetimeValidator(
  $el /* jQuery element to validate */,
  required /* is the element required according to the `[required]` attribute */
) {
  let val = $el.val();

  let form = $el.closest("form");

  let start_date_id = $el.data("start-date");
  let end_date_id = $el.data("end-date");
  let start_time_id = $el.data("start-time");
  let end_time_id = $el.data("end-time");

  let start_date = $("#" + start_date_id).val();
  let start_time = start_time_id ? $("#" + start_time_id).val() : "";
  let end_date = $("#" + end_date_id).val();
  let end_time = end_time_id ? $("#" + end_time_id).val() : "";

  //first check if required
  if (required === true && !val) {
    return false;
  }

  //now if all values are set we should check whether the the end date/time is after the start date/time
  if (start_date && end_date) {
    let full_start = start_date;
    let full_end = end_date;

    //add the time if set
    if (start_time) {
      full_start += " " + start_time;
    }

    if (end_time) {
      full_end += " " + end_time;
    }

    let s = new Date(full_start);
    let e = new Date(full_end);

    if (s.getTime() >= e.getTime()) {
      $("#data-abide-msg")
        .html(
          '<p><em class="fa-exclamation-triangle fa no-link"></em> The end date/time needs to be after the start date/time.</p>'
        )
        .show();

      //set error labels on all fields that have been passed
      if (start_date_id) {
        form.foundation("addErrorClasses", $("#" + start_date_id), []);
      }

      if (start_time_id) {
        form.foundation("addErrorClasses", $("#" + start_time_id), []);
      }

      if (end_date_id) {
        form.foundation("addErrorClasses", $("#" + end_date_id), []);
      }

      if (end_time_id) {
        form.foundation("addErrorClasses", $("#" + end_time_id), []);
      }

      return false;
    }
  }

  $("#data-abide-msg")
    .html(
      '<p><em class="fa-exclamation-triangle fa no-link"></em> There are some errors in your form.</p>'
    )
    .hide();

  //if passed then remove all data-abide errors from the fields

  if (start_date_id && start_date) {
    form.foundation("removeErrorClasses", $("#" + start_date_id));
  }

  if (start_time_id && start_time) {
    form.foundation("removeErrorClasses", $("#" + start_time_id));
  }

  if (end_date_id && end_date) {
    form.foundation("removeErrorClasses", $("#" + end_date_id));
  }

  if (end_time_id && end_time) {
    form.foundation("removeErrorClasses", $("#" + end_time_id));
  }

  return true;
};
